import ProductsCore from 'piramis-base-components/src/components/Shop/Products'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import {
	CreateProductModel,
	EditProductModel,
	Product,
	ProductState,
} from 'piramis-base-components/src/components/Shop/Products/types'
import store from '@/store/store'
import { ShopService } from "@/includes/services/ShopService";

export default class ProductsHelper extends ProductsCore {
	constructor(currencyList: Array<SelectOptionData>, products: Array<Product>) {
		super(currencyList, products)
	}

	getTargetId(): string {
		return store.state.boardsState.activeBoard!.board
	}

	protected editProduct(product: EditProductModel): Promise<Product> {
		return store.dispatch('editProduct', product)
	}

	protected createProduct(product: CreateProductModel): Promise<Product> {
		return store.dispatch('createProduct', product)
	}

	protected removeProduct(id: number): Promise<ProductState.Hidden | ProductState.Removed> {
		return store.dispatch('deleteProduct', id)
	}

	protected restoreProduct(id: number): Promise<void> {
		return ShopService.restoreProduct('tg', { board_key: store.state.boardsState.activeBoard!.board, id })
	}
}
